import React from "react";
import i18next from "i18next";
import grb from "./sliki.png/grb.png";
import mkd from "./sliki.png/mkd.jpg";
import { Grid } from "@material-ui/core";
export default function HeaderTranslation() {
  function handleClick(lang) {
    i18next.changeLanguage(lang);
  }
  return (
    <div>
      <Grid>
        <nav style={{ padding: "0", margin: "0" }}>
          <button
            onClick={() => handleClick("en")}
            style={{
              width: "35px",
              height: "0px",
              marginRight: "5px",
              padding: "0px",
              border: "none",
            }}
          >
            <img src={grb} alt={grb} style={{ width: "35px" }} />
          </button>
          <button
            onClick={() => handleClick("mk")}
            style={{
              width: "40px",
              marginRight: "5px",
              padding: "0px",
              border: "none",
              height: "0px",
            }}
          >
            <img src={mkd} alt={mkd} style={{ width: "40px" }} />
          </button>
        </nav>
      </Grid>
    </div>
  );
}
