import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, Button } from "@material-ui/core";
import { BrowserRouter as Router } from "react-router-dom";
import ana from "./ana.png";
import { makeStyles } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import {
  Typography,
  IconButton,
  Drawer,
  Link,
  MenuItem,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import HeaderTranslation from "./HeaderTranslation.js";
import { useTranslation } from "react-i18next";
import { ClassNames } from "@emotion/react";

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: "#dfbce8",
    paddingLeft: "1px",
    paddingRight: "1px",
    "@media (max-width: 700px)": {
      paddingLeft: 0,
    },
    margin: "0px",
  },
  headerScrolling: {
    opacity: "0.76",
    backgroundColor: "#dfbce8",
    "@media (max-width: 700px)": {
      paddingLeft: 0,
    },
    margin: "0px",
  },
  logo: {
    width: "50px",
    paddingLeft: "50px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    color: "white",
    opacity: 1,
  },
  buttonScrolling: {
    opacity: 1,
    color: "#5f0075",
  },
}));

export default function Header() {
  const [navBackground, setNavBackground] = useState("headerScrolling");
  const { t } = useTranslation();
  const headersData = [
    {
      label: <> {t("header.1")}</>,
      href: "/",
    },
    {
      label: <> {t("header.2")}</>,
      href: "/zanas",
    },
    {
      label: <> {t("header.3")}</>,
      href: "/VideoFirst",
    },
    {
      label: <> {t("header.4")}</>,
      href: "/blog",
    },
    {
      label: <> {t("header.6")}</>,
      href: "/login",
    },
    {
      label: <> {t("header.5")}</>,
      href: "/RecipesFirst",
    },
  ];
  const { header, logo, toolbar, headerScrolling, button, buttonScrolling } =
    useStyles();
  const [small, setSmall] = useState(true);

  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        <div>{femmecubatorLogo}</div>

        <div style={{ textAlign: "right", paddingRight: "1px" }}>
          {" "}
          {getMenuButtons()}
        </div>
        <div>
          <HeaderTranslation />{" "}
        </div>
      </Toolbar>
    );
  };

  const femmecubatorLogo = (
    <img
      src={ana}
      className={logo}
      alt="logo"
      style={{ boxSizing: "initial" }}
    />
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Button
          className={small ? button : buttonScrolling}
          {...{
            key: label,
            color: "inherit",
            to: href,
            component: RouterLink,
          }}
          onClick={() => (window.location.href = href)}
          style={{ textAlign: "right", paddingRight: "1px" }}
        >
          {label}
        </Button>
      );
    });
  };
  const { menuButton, drawerContainer } = useStyles();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar>
        <IconButton
          {...{
            edge: "start",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon />
        </IconButton>
        <HeaderTranslation />

        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>{getDrawerChoices()}</div>
        </Drawer>

        <div>{femmecubatorLogo}</div>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Link
          {...{
            key: label,
            color: "#652496",
            to: href,
            component: RouterLink,
          }}
          onClick={() => (window.location.href = href)}
        >
          <MenuItem>{label}</MenuItem>
        </Link>
      );
    });
  };
  const classes = useStyles();
  const navRef = React.useRef();
  navRef.current = navBackground;
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 80;
      if (show) {
        setNavBackground("headerScrolling");
      } else {
        setNavBackground("header");
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={ClassNames.root}>
      <header>
        <AppBar position="fixed" className={classes[navRef.current]}>
          {mobileView ? displayMobile() : displayDesktop()}
        </AppBar>
      </header>
    </div>
  );
}
